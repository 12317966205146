import React from 'react';

const Image = ({ src, alt, width, height, sizes, effects, quality }) => {
	const baseUrl = src.split(',')[0];
	let srcSet = '';
	let effectString = '';
	let qIncrement = 0;

	effects.forEach((effect) => (effectString += effect));
	sizes.forEach((size, i) => {
		qIncrement = qIncrement + 10 > quality ? quality : (i + 1) * 10;
		return (srcSet += `${baseUrl}&w=${size}&q=${qIncrement}${effectString} ${size}w, `);
	});
	srcSet.slice(0, -1);

	return (
		<img
			srcSet={srcSet}
			src={`${baseUrl}&w=200&q=10,format`}
			alt={alt}
			style={{ width: width, height: height }}
		/>
	);
};

Image.defaultProps = {
	alt     : '',
	width   : '',
	height  : '',
	sizes   : [ 200, 300, 400, 500, 600, 700, 800 ],
	effects : [],
	quality : 25
};

export default Image;
